import "../AuthStyle.css";
import { Link } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { AppContext } from "../../../context/AppContextProvider";
import {
  userRegister,
  userRegisterValidate,
  sendOTPSMS,
} from "../../../network/network";
import {
  Form,
  Input,
  Button,
  message,
  Col,
  Row,
  Radio,
  Spin,
  Dropdown,
} from "antd";

import { useTranslation } from "react-i18next";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Pricing from "../../../components/Pricing/Pricing";
import {
  PERIOD_DAILY,
  PERIOD_MONTHLY,
  PERIOD_YEARLY,
} from "../../../configs/constants";
import { DownOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

const Register = () => {
  const { setAuthUser, setFullPageLoading, appConfig, setAppConfigWrapper } =
    useContext(AppContext);
  const [pricingVisible, setPricingVisible] = useState(true);
  const [OTPFormVisible, setOTPFormVisible] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const [intervalID, setIntervalID] = useState(false);
  const [loading, setloading] = useState(false);
  const [planSelection, setPlanSelection] = useState({});
  const [resellerNumber, setResellerNumber] = useState("");
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [registerForm] = Form.useForm();
  const { t } = useTranslation();
  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    setResellerNumber(query.get("reseller_number"));
    if (resellerNumber) {
      registerForm.setFieldsValue({
        reseller_number: resellerNumber,
      });
    }
  }, [registerForm, search, resellerNumber]);

  useEffect(() => {
    if (remainingTime < 1) {
      clearInterval(intervalID);
    }
  }, [remainingTime, intervalID]);

  const onOTPFormFinish = (otpValues) => {
    registerForm.validateFields().then((values) => {
      setloading(true);
      values.selected_addons = JSON.stringify(
        selectedAddons.map((item) => ({
          id: item.id,
          name: item.name,
        }))
      );
      userRegister({
        ...otpValues,
        ...values,
        ...planSelection,
      })
        .then((response) => {
          if (response.data && response.data.success) {
            // message.success(t("register_successfully"));
            setFullPageLoading(true);
            localStorage.setItem(
              "access_token",
              response.data.data.user.access_token
            );
            setAuthUser(response.data.data.user);
            setFullPageLoading(false);
          } else {
            let error = response.response;
            if (error.status === 422) {
              message.error(error.data.message);
            } else if (error.status === 500) {
              let errorMessages = Object.values(error.data.data.errors);
              errorMessages.forEach(function (key, value) {
                let temp = Object.values(key);
                message.error(temp[0]);
              });
            } else if (
              error.status === 400 &&
              error.data.message === "OTP validation Failed."
            ) {
              NotificationWithIcon(
                "error",
                "OTP validation Failed, Try Again."
              );
            }
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setloading(false);
        });
    });
  };

  const startTimer = () => {
    setRemainingTime(120);
    let interval = setInterval(() => {
      setRemainingTime((remainingTime) => remainingTime - 1);
    }, 1000);
    setIntervalID(interval);
  };

  const handleSendOTPSMS = () => {
    let mobileNumber = registerForm.getFieldValue("phone");
    sendOTPSMS({ number: mobileNumber }).then((response) => {
      startTimer();
      setOTPFormVisible(true);
    });
  };

  const handleRegisterValidation = () => {
    setloading(true);
    registerForm.validateFields().then((values) => {
      userRegisterValidate(values) // OTP SMS sent as well
        .then((response) => {
          setloading(false);
          if (response.data && response.data.success) {
            startTimer();
            setOTPFormVisible(true);
          } else {
            let error = response.response;
            if (error.status === 400) {
              let errorMessages = Object.values(error.data.data.errors);
              NotificationWithIcon(
                "error",
                "Validation Error",
                errorMessages[0][0]
              );
            } else if (error.status === 500) {
              let errorMessages = Object.values(error.data.data.errors);
              errorMessages.forEach(function (key, value) {
                let temp = Object.values(key);
                message.error(temp[0]);
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const callbackSelection = (period, users_count, selectedAddons) => {
    // Hide Pricing Component
    setPricingVisible(false);
    // set selected addons
    setSelectedAddons(selectedAddons);
    // Set Plan Selection
    setPlanSelection({
      period,
      users_count,
    });

    // Show Plan name
    let name = "";

    if (period === PERIOD_YEARLY) {
      name = "Yearly";
    } else if (period === PERIOD_MONTHLY) {
      name = "Monthly";
    } else if (period === PERIOD_DAILY) {
      name = "Daily";
    }

    registerForm.setFieldsValue({
      plan_text: name,
      type: 2,
    });
  };

  const LangOptions = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            setAppConfigWrapper({ direction: "ltr" });
          }}
        >
          English
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            setAppConfigWrapper({ direction: "rtl" });
          }}
        >
          عربي
        </div>
      ),
    },
  ];

  return (
    <>
      <Spin spinning={loading}>
        <div
          style={{
            backgroundImage: "url('/images/bg-img.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "top center",
            boxShadow: "inset 0 0 0 2000px rgb(51 51 51 / 10%)",
            minHeight: "100vh",
            direction: appConfig.direction
              ? appConfig.direction === "ltr"
                ? "ltr"
                : "rtl"
              : "ltr",
          }}
        >
          <div className="main-wrapper">
            <header className="header">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex" }}>
                  <a
                    href="https://anypos.app/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ display: "flex" }}
                  >
                    <img
                      src="/anypos-logo.svg"
                      alt="anypos"
                      className="main_logo"
                    />
                  </a>
                </div>
                <div
                  className="Button-area"
                  style={{
                    display: "flex",
                    columnGap: "10px",
                    alignItems: "center",
                  }}
                >
                  {!resellerNumber && (
                    <a
                      href="https://anypos.app/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button className="border_btn md_down_display_none">
                        {t("visit_website")}
                      </Button>
                    </a>
                  )}
                  {!resellerNumber && (
                    <Link to="/login">
                      <Button className="solid_btn1">{t("sign_in")}</Button>
                    </Link>
                  )}
                  <Dropdown
                    trigger={["click"]}
                    menu={{
                      items: LangOptions,
                    }}
                    className="md_down_display_none"
                  >
                    <Button className="solid_btn2">
                      {appConfig.direction
                        ? appConfig.direction === "ltr"
                          ? "English"
                          : "عربي"
                        : "English"}
                      <span
                        style={{
                          margin: appConfig.direction
                            ? appConfig.direction === "ltr"
                              ? "0 0 0 8px"
                              : "0 8px 0 0"
                            : "0 0 0 8px",
                        }}
                      >
                        <DownOutlined
                          style={{
                            fontSize: "12px",
                          }}
                        />
                      </span>
                    </Button>
                  </Dropdown>
                  <Dropdown
                    trigger={["click"]}
                    menu={{
                      items: LangOptions,
                    }}
                    className="md_up_display_none"
                  >
                    <img
                      src="/icons/Globe.png"
                      alt="language"
                      style={{ width: "fit-content", height: "fit-content" }}
                    />
                  </Dropdown>
                </div>
              </div>
            </header>
            <div className="auth_area">
              {pricingVisible ? (
                <Pricing callbackSelection={callbackSelection} />
              ) : (
                <>
                  <div style={{ display: !OTPFormVisible ? "block" : "none" }}>
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={3} xl={4} xxl={5}></Col>
                      <Col xs={24} sm={24} md={24} lg={18} xl={16} xxl={14}>
                        <div
                          style={{
                            backgroundColor: "#ffffffcc",
                            padding: "20px",
                            boxShadow: "0px 0px 10px #00000029",
                            borderRadius: "20px",
                          }}
                        >
                          <Form
                            name="register-user-form"
                            layout="vertical"
                            onFinish={handleRegisterValidation}
                            form={registerForm}
                          >
                            <div
                              style={{
                                padding: "10px 0 30px 0",
                                textAlign: "center",
                              }}
                            >
                              <h2>{t("sign_up")}</h2>
                            </div>

                            <Row
                              gutter={{
                                xs: 0,
                                sm: 20,
                                md: 20,
                                lg: 20,
                                xl: 20,
                                xxl: 20,
                              }}
                            >
                              <Col
                                xs={24}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                              >
                                <Form.Item
                                  name="first_name"
                                  label={t("first_name")}
                                  rules={[
                                    {
                                      required: true,
                                      message: t(
                                        "validate_first_name_required"
                                      ),
                                      whitespace: true,
                                    },
                                    {
                                      min: 3,
                                      message: t(
                                        "validate_first_name_min_characters"
                                      ),
                                    },
                                  ]}
                                  hasFeedback
                                >
                                  <Input size="large" />
                                </Form.Item>
                              </Col>
                              <Col
                                xs={24}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                              >
                                <Form.Item
                                  name="last_name"
                                  label={t("last_name")}
                                  rules={[
                                    {
                                      required: true,
                                      message: t("validate_last_name_required"),
                                      whitespace: true,
                                    },
                                    {
                                      min: 3,
                                      message: t(
                                        "validate_last_name_min_characters"
                                      ),
                                    },
                                  ]}
                                  hasFeedback
                                >
                                  <Input size="large" />
                                </Form.Item>
                              </Col>
                              <Col
                                xs={24}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                              >
                                <Form.Item
                                  name="email"
                                  label={t("email")}
                                  rules={[
                                    {
                                      type: "email",
                                      message: t("validate_email_invalid"),
                                    },
                                  ]}
                                  hasFeedback
                                >
                                  <Input size="large" />
                                </Form.Item>
                              </Col>
                              <Col
                                xs={24}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                              >
                                <Form.Item
                                  name="phone"
                                  label={
                                    t("phone_number") + " eg: +966 5xxxxxxx"
                                  }
                                  rules={[
                                    {
                                      required: true,
                                      message: t("validate_phone_required"),
                                    },
                                    {
                                      min: 12,
                                      message: t("validate_phone_exact_limit"),
                                    },
                                    {
                                      max: 12,
                                      message: t("validate_phone_exact_limit"),
                                    },
                                  ]}
                                >
                                  <PhoneInput
                                    localization={["sa"]}
                                    masks={{ sa: "(...) ..-..-.." }}
                                    placeholder="+966 (123) 45-67-89"
                                    country={"sa"}
                                    onlyCountries={["sa"]}
                                    style={{
                                      direction: "ltr",
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col
                                xs={24}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                              >
                                <Form.Item
                                  name="password"
                                  label={t("password")}
                                  rules={[
                                    {
                                      required: true,
                                      message: t("validate_password_required"),
                                    },
                                    {
                                      min: 6,
                                      message: t(
                                        "validate_password_min_characters"
                                      ),
                                    },
                                  ]}
                                  hasFeedback
                                >
                                  <Input.Password size="large" />
                                </Form.Item>
                              </Col>
                              <Col
                                xs={24}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                              >
                                <Form.Item
                                  name="password_confirmation"
                                  label={t("confirm_password")}
                                  dependencies={["password"]}
                                  hasFeedback
                                  rules={[
                                    {
                                      required: true,
                                      message: t(
                                        "validate_password_confirm_not_matched"
                                      ),
                                    },
                                    ({ getFieldValue }) => ({
                                      validator(_, value) {
                                        if (
                                          !value ||
                                          getFieldValue("password") === value
                                        ) {
                                          return Promise.resolve();
                                        }

                                        return Promise.reject(
                                          new Error(
                                            t(
                                              "validate_password_confirm_not_matched"
                                            )
                                          )
                                        );
                                      },
                                    }),
                                  ]}
                                >
                                  <Input.Password size="large" />
                                </Form.Item>
                              </Col>
                              <Col
                                xs={24}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                              >
                                <Form.Item
                                  name="plan_text"
                                  label={t("selected_plan")}
                                >
                                  <Input size="large" disabled={true} />
                                </Form.Item>
                              </Col>
                              <Col
                                xs={24}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                              >
                                <Form.Item
                                  name="business_name"
                                  label={t("business_name")}
                                  rules={[
                                    {
                                      required: true,
                                      message: t(
                                        "validate_business_name_required"
                                      ),
                                      whitespace: true,
                                    },
                                  ]}
                                  hasFeedback
                                >
                                  <Input size="large" />
                                </Form.Item>
                              </Col>
                              <Col
                                xs={24}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                              >
                                <Form.Item
                                  name="preferred_contact_time"
                                  label={t("preferred_contact_time")}
                                  rules={[
                                    {
                                      required: true,
                                      message: t(
                                        "validate_preferred_contact_time_required"
                                      ),
                                    },
                                  ]}
                                >
                                  <Radio.Group>
                                    <Radio
                                      value={"09:00 AM - 02:00 PM"}
                                      style={{ direction: "ltr" }}
                                    >
                                      09:00 AM - 02:00 PM
                                    </Radio>
                                    <Radio
                                      value={"02:00 PM - 05:00 PM"}
                                      style={{ direction: "ltr" }}
                                    >
                                      02:00 PM - 05:00 PM
                                    </Radio>
                                  </Radio.Group>
                                </Form.Item>
                              </Col>
                              <Col
                                xs={24}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                              >
                                <Form.Item
                                  name="type"
                                  style={{ display: "none" }}
                                >
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                              <Col
                                xs={24}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                              >
                                <Form.Item
                                  name="reseller_number"
                                  style={{ display: "none" }}
                                >
                                  <Input type="hidden" />
                                </Form.Item>
                              </Col>
                            </Row>

                            <div
                              style={{
                                width: "100%",
                                justifyContent: "space-between",
                                display: "flex",
                                margin: "10px 0",
                              }}
                            >
                              <Button
                                loading={loading}
                                onClick={() => setPricingVisible(true)}
                                className="border_btn"
                              >
                                {t("back")}
                              </Button>
                              <Button
                                loading={loading}
                                htmlType="submit"
                                className="solid_btn1"
                              >
                                {t("sign_up")}
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={3} xl={4} xxl={5}></Col>
                    </Row>
                  </div>
                  <div style={{ display: OTPFormVisible ? "block" : "none" }}>
                    <Row>
                      <Col xs={24} sm={3} md={4} lg={6} xl={7} xxl={8}></Col>
                      <Col xs={24} sm={18} md={16} lg={12} xl={10} xxl={8}>
                        <div
                          style={{
                            backgroundColor: "#ffffffcc",
                            padding: "20px",
                            boxShadow: "0px 0px 10px #00000029",
                            borderRadius: "20px",
                          }}
                        >
                          <Form
                            name="validate-otp-form"
                            layout="vertical"
                            onFinish={onOTPFormFinish}
                          >
                            <div
                              style={{
                                padding: "10px 0 30px 0",
                                textAlign: "center",
                              }}
                            >
                              <h2>{t("verify_otp")}</h2>
                            </div>
                            <p>
                              {t("opt_message")}
                              <br />
                              {t("if_you_want_to_change_phone_number")}{" "}
                              <Link
                                to="#"
                                onClick={() => setOTPFormVisible(false)}
                              >
                                {t("click_here")}
                              </Link>
                            </p>
                            <Form.Item
                              name="otp"
                              label="OTP"
                              rules={[
                                {
                                  required: true,
                                },
                                {
                                  max: 4,
                                },
                                {
                                  min: 4,
                                },
                              ]}
                              hasFeedback
                            >
                              <Input.Password
                                type="number"
                                minLength={4}
                                maxLength={4}
                                size="large"
                              />
                            </Form.Item>
                            <div
                              style={{
                                width: "100%",
                                margin: "20px 0 10px 0",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Form.Item>
                                <Button
                                  className="border_btn"
                                  disabled={remainingTime < 1 ? false : true}
                                  onClick={handleSendOTPSMS}
                                >
                                  {t("resend_code")}
                                </Button>
                                <br />
                                <small
                                  style={{
                                    display:
                                      remainingTime < 1
                                        ? "none"
                                        : "inline-block",
                                  }}
                                >
                                  {t("enable_in")}{" "}
                                  {Math.floor(remainingTime / 60)
                                    .toString()
                                    .padStart(2, "0") +
                                    ":" +
                                    Math.floor(remainingTime % 60)
                                      .toString()
                                      .padStart(2, "0")}
                                </small>
                              </Form.Item>
                              <Form.Item>
                                <Button
                                  htmlType="submit"
                                  className="solid_btn1"
                                >
                                  {t("validate")}
                                </Button>
                              </Form.Item>
                            </div>
                          </Form>
                        </div>
                      </Col>
                      <Col xs={24} sm={3} md={4} lg={6} xl={7} xxl={8}></Col>
                    </Row>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {/* <div className="authSection">
          <div className="lang_button">
            <Radio.Group
              value={appConfig.direction}
              onChange={(e) => {
                setAppConfigWrapper({ direction: e.target.value });
              }}
              buttonStyle="solid"
              defaultValue="ltr"
            >
              <Radio.Button value="ltr">English</Radio.Button>
              <Radio.Button value="rtl">عربي</Radio.Button>
            </Radio.Group>
          </div>
          <div style={{ width: "100%" }}>
            <Row>
              <Col
                xs={24}
                sm={24}
                md={18}
                lg={18}
                xl={18}
                xxl={18}
                className="authSection-right"
              >
                {pricingVisible ? (
                  <>
                    <Pricing callbackSelection={callbackSelection} />
                  </>
                ) : (
                  
                )}
              </Col>
            </Row>
          </div>
        </div> */}
      </Spin>
    </>
  );
};

export default Register;
