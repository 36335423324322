const trans_en_GB = {
  // Form Fields
  name: "Name",
  first_name: "First Name",
  last_name: "Last Name",
  name_ar: "Name in Arabic",
  name_en: "Name in English",
  price: "Price",
  email: "E-mail",
  password: "Password",
  confirm_password: "Confirm Password",
  phone_number: "Phone Number",
  business_name: "Business Name",
  business_type: "Business Type",
  shop_address: "Shop Address",
  cr_number: "CR number",
  vat_number: "VAT Number",
  business_logo: "Business Logo",
  image: "Image",
  is_taxable: "Is Taxable",
  is_refunded: "Is Refunded",
  is_QRproduct: "Is QR Product",
  is_stockable: "Is Stockable",
  reset: "Reset",
  cart: "Cart",
  address: "Address",
  company: "Company",
  individual: "Individual",
  code: "Code",
  branch: "Branch",
  active: "Active",
  inactive: "Inactive",
  subscribe: "Subscribe",
  unsubscribe: "Unsubscribe",
  no_of_employees: "# of Employees",
  no_of_licenses: "# of Licenses",
  vat_certificate: "VAT Certificate",
  cr_certificate: "CR Certificate",
  change: "Change",
  preview: "Preview",
  add_license: "Add License",
  purchase_license: "Purchase License",
  add_free_license: "Add Free License",
  buy_more_license: "Buy More Licenses!",
  buy_more_license_error: "Buy More License to create more User(s).",
  buyer_company_name: "Buyer Company Name",
  buyer_company_vat: "Buyer Company VAT No.",
  city: "City",
  state: "State",
  country: "Country",
  postal_code: "Postal Code",
  calculations: "Calculations",
  description: "Description",
  attachment: "Attachment",
  no_attachment: "No Attachment",
  url: "URL",
  secret_key: "Secret Key",
  integration_type: "Integration Type",
  customer_name: "Customer Name",
  vehicle_number: "Vehicle Number",
  vehicle_color: "Vehicle Color",
  reseller_number: "Reseller Number",
  type: "Type",
  street: "Street",
  building_number: "Building Number",
  plot_id_number: "Plot ID Number",

  // Form Validations
  validate_preferred_contact_time_required:
    "Please choose the preferred contact time.",
  validate_email_required: "The email field is required.",
  validate_email_invalid: "The input is not a valid E-mail!",
  validate_password_required: "The password field is required.",
  validate_password_min_characters: "password must be of minimum 6 characters.",
  validate_password_confirm_not_matched: "Confirm password does not matched.",
  validate_name_required: "The name field is required.",
  validate_name_min_characters: "Name must be of minimum 3 characters.",
  validate_first_name_required: "The first name field is required.",
  validate_first_name_min_characters:
    "First name must be of minimum 3 characters.",
  validate_last_name_required: "The last name field is required.",
  validate_last_name_min_characters:
    "Last name must be of minimum 3 characters.",
  validate_phone_required: "The phone number field is required.",
  validate_phone_exact_limit: "Phone must be of exactly 12 digits.",
  validate_business_name_required: "The business name field is required.",
  validate_business_type_required: "Please select business type.",
  validate_cr_number_required: "The CR# field is required.",
  validate_cr_number_exact_limit: "The CR# must be of exactly 10 Number.",
  validate_vat_number_required: "The VAT# field is required.",
  validate_vat_number_exact_limit: "The VAT# must be of exactly 15 Number.",
  validate_city_required: "The City field is required.",
  validate_state_required: "The State field is required.",
  validate_country_required: "The Country field is required.",
  validate_post_code_required: "The Postal Code field is required.",
  validate_address: "The address field is required.",
  validate_shop_address_required: "Please input your Shop Address!",
  validate_image_file_type: "You can only upload JPG/PNG file!",
  validate_image_size: "Image must be smaller than 2MB!",
  validate_business_logo_required: "Business Logo is required!",
  validate_image_pdf_file_type: "You can only upload JPG/PNG or PDF file!",
  validate_image_size_10: "Image must be smaller than 10MB!",
  validate_cr_certificate_required: "CR Certificate is required!",
  validate_vat_certificate_required: "VAT Certificate is required!",
  validate_type_required: "The Type field is required.",
  validate_excel_file_type: "You can only upload Excel file!",
  validate_file_size_1: "File must be smaller than 1MB!",
  validate_attachment_required: "The attachment field is required!",

  validate_product_name_required: "Please input Product name!",
  validate_product_price_required: "Please input Product price!",
  validate_product_barcode_required: "Please input Product barcode!",
  validate_product_category_required: "Please select Product category!",
  validate_product_unit_required: "Please select Product unit!",
  validate_address_required: "Please input Shop Address!",
  validate_branch_required: "Please select Branch!",

  validate_description_required: "The description field is required.",

  validate_cash_required: "The cash field is required.",
  validate_card_required: "The card field is required.",
  validate_reference_required: "The reference number field is required.",
  validate_discount_required: "The discount percentage is required.",

  validate_url_required: "The URL field is required.",
  validate_url_invalid: "The input is not a valid URL!",
  validate_secret_key_required: "The Secret Key field is required.",
  validate_integration_type_required: "The integration type is required.",
  validate_date_required: "The Date field is required.",
  validate_customer_name_required: "The Customer name field is required.",
  validate_vehicle_number_required: "The Vehicle number field is required.",
  validate_vehicle_color_required: "The Vehicle color field is required.",

  validate_street_required: "The Street field is required.",
  validate_building_number_required: "The Building Number field is required.",
  validate_plot_id_number_required: "The Plot ID Number field is required.",
  validate_entity_required: "This field is required.",

  // Login
  dont_have_account: "Don't have an account?",
  login: "Login",
  visit_website: "Visit website",

  // Register
  already_have_an_account: "Already have an account?",
  sign_in: "Sign in",
  sign_up: "Sign up",
  register_successfully: "You have been Registered Successfully!",
  selected_plan: "Selected Plan",
  account_successfully_created: "Your account has been successfully created!",
  details_activate_company:
    "Fill the details to activate your company or skip for now.",

  // Forgot
  forgot: "Forgot password",
  forgot_password: "Forgot password?",
  send_otp: "Send OTP",
  verify_otp: "Verify OTP",
  reset_password: "Reset password",
  reset_password_successfully: "Your password reset Successfully!",

  // Pricing
  pricing_plan: "Pricing Plan",
  number_of_users: "Number of users",
  pricing: "Pricing",
  daily: "Daily",
  monthly: "Monthly",
  yearly: "Yearly",
  trial: "Trial",
  pro: "Pro",
  sign_up_free_trial: "Sign-up 1 Year Free Trial",
  pay: "Pay",
  VAT_once_trial_period_is_over: "VAT once trial period is over.",
  month_per_user: "Month (Per user price)",
  day_per_user: "Day (Per user price)",
  for_1_user: "for 1 User",

  // otp form

  opt_message:
    "A message with the OTP code has been sent on your mobile number. Kindly fill in the below field and Validate.",
  if_you_want_to_change_phone_number: "If you want to change phone number",
  click_here: "Click Here.",
  resend_code: "Resend Code",
  enable_in: "Enable in",
  validate: "Validate",

  // Store Configuration
  beauty_and_personal_care: "Beauty & Personal Care",
  retail_food_drinks: "Retail - Food - Drinks",
  healthcare_and_fitness: "Healthcare and Fitness",
  home_and_repair: "Home and Repair",
  entertainment: "Entertainment",
  professional_services: "Professional Services",
  transportation: "Transportation",
  shop_configuration_successfully: "Shop configuration has been completed.",
  allow_round_off: "Allow round off to 2 digits",
  preferred_contact_time: "Preferred contact time",

  // Menus
  menu_home: "Home",
  menu_pos: "POS",
  menu_products_list: "Products list",
  menu_products: "Products",
  menu_categories: "Categories",
  menu_units: "Units",
  menu_transactions: "Transactions",
  menu_reports: "Reports",
  menu_sales_summary: "Sales Summary",
  menu_sales_by_items: "Sales By Items",
  menu_sales_by_categories: "Sales By Categories",
  menu_refunds_by_items: "Refunds By Items",
  menu_refunds_by_categories: "Refunds By Categories",
  menu_report_by_branch: "Report By Branch",
  menu_store_configuration: "Store Configuration",
  menu_subscriptions: "Subscriptions",
  menu_branches: "Branches",
  menu_orders: "Orders",
  menu_view_order: "View Order",
  menu_edit_order: "Edit Order",
  menu_tables: "Tables",
  menu_employees: "Employees",
  menu_invoices: "Invoices",
  menu_devices: "Devices",
  menu_help_desk: "Help Desk",
  menu_discounts: "Discounts",
  menu_external_integration: "External Integration",
  menu_addons: "Addons",
  menu_addon_detail: "Addon Detail",
  menu_inventory: "Inventory",
  menu_stock: "Stock",
  menu_stock_adjustment: "Stock Adjustment",
  menu_view_stock_adjustment: "View Stock Adjustment",
  menu_stock_transfer: "Stock Transfer",
  menu_view_transfer: "View Transfer",
  menu_edit_transfer: "Edit Transfer",
  menu_customers: "Customers",
  menu_customer_details: "Customer Details",
  menu_sales_invoice: "A4 Sales Invoice",
  menu_view_sales_invoice: "View A4 Sales Invoice",

  // Products
  product_add: "Add Product",
  product_added: "Product Added",
  product_added_successfully: "Product has been added successfully!",
  product_edit: "Edit Product",
  product_updated: "Product Updated",
  product_updated_successfully: "Product has been updated successfully!",
  product_deleted: "Product Deleted",
  product_deleted_successfully: "Product has been deleted successfully!",
  products_import: "Import Products",
  products_import_successfully: "Products has been import successfully!",

  // Product Categories
  category_add: "Add Category",
  category_name: "Category Name",
  category_edit: "Edit Category",
  category_name_required: "Please input product category name!",
  category_added: "Category Added",
  category_added_successfully: "Category has been added successfully!",
  category_updated: "Category updated",
  category_updated_successfully: "Category has been updated successfully!",
  category_deleted: "Category Deleted",
  category_deleted_successfully: "Category has been deleted successfully!",
  categories_import: "Import Categories",
  categories_import_successfully: "Categories has been import successfully!",

  // Product Units
  unit_add: "Add Unit",
  unit_name: "Unit Name",
  Unit_edit: "Edit Unit",
  unit_name_required: "Please input product unit name!",
  unit_added: "Unit Added",
  unit_added_successfully: "Unit has been added successfully!",
  unit_updated: "Unit Updated",
  unit_updated_successfully: "Unit has been updated successfully!",
  unit_deleted: "Unit Deleted",
  unit_deleted_successfully: "Unit has been deleted successfully!",
  units_import: "Import Units",
  units_import_successfully: "Unit has been import successfully!",

  // Branches
  branch_add: "Add Branch",
  branch_added: "Branch Added",
  branch_added_successfully: "Branch has been added successfully!",
  branch_deleted: "Branch Deleted",
  branch_deleted_successfully: "Branch has been deleted successfully!",
  branch_edit: "Edit Branch",
  branch_updated: "Branch Updated",
  branch_updated_successfully: "Branch has been updated successfully!",

  // Customers
  customer_add: "Add Customer",
  customer_added: "Customer Added",
  customer_added_successfully: "Customer has been added successfully!",
  customer_deleted: "Customer Deleted",
  customer_deleted_successfully: "Customer has been deleted successfully!",
  customer_edit: "Edit Customer",
  customer_updated: "Customer Updated",
  customer_updated_successfully: "Customer has been updated successfully!",

  // Orders
  mark_as_in_progress: "Mark as in Progress",
  mark_as_completed: "Mark as Complete",
  add_order: "Add Order",
  cancel_order: "Cancel Order",
  order_added: "Order Added",
  order_added_successfully: "The order has been added successfully!",
  order_updated: "Order Updated",
  order_updated_successfully: "The order has been updated successfully!",

  // Tables
  table_add: "Add Table",
  table_added: "Table Added",
  table_added_successfully: "Table has been added successfully!",
  is_drive_thru: "Drive Thru",
  drive_thru_add: "Add Drive-through",
  drive_thru_added: "Drive-through Added",
  drive_thru_added_successfully: "Drive-through has been added successfully!",
  entity_updated: "Entity Updated",
  entity_updated_successfully: "Entity has been Updated successfully!",
  entity_deleted: "Entity Deleted",
  entity_deleted_successfully: "Entity has been deleted successfully!",
  add_table: "Add Table",
  update_table: "Update Table",
  assign_table_to_order: "Assign Table to Order",
  select_table: "Select Table",
  validate_table_required: "The Table field is required.",

  // Employees
  employee_add: "Add Employee",
  employee_added: "Employee Added",
  employee_added_successfully: "Employee has been added successfully!",
  employee_edit: "Edit Employee",
  employee_updated: "Employee Updated",
  employee_updated_successfully: "Employee has been updated successfully!",
  employee_activate: "Activate Employee",
  employee_activated: "Employee Activated",
  employee_activated_successfully: "Employee has been activated successfully!",
  employee_deactivate: "Deactivate Employee",
  employee_deactivated: "Employee Deactivated",
  employee_deactivated_successfully:
    "Employee has been deactivated successfully!",
  employee_deleted: "Employee Deleted",
  employee_deleted_successfully: "Employee has been deleted successfully!",

  // Helpdesk
  ticket_add: "Add Ticket",
  ticket_added: "Ticket Added",
  ticket_added_successfully: "The ticket has been added successfully!",
  ticket_updated: "Ticket Updated",
  ticket_updated_successfully: "The ticket has been updated successfully!",

  // Discount
  discount_add: "Add Discount",
  discount_added: "Discount Added",
  discount_added_successfully: "Discount has been added successfully!",
  discount_deleted: "Discount Deleted",
  discount_deleted_successfully: "Discount has been deleted successfully!",
  discount_edit: "Edit Discount",
  discount_updated: "Discount Updated",
  discount_updated_successfully: "Discount has been updated successfully!",

  // External Integration
  connection_add: "Add Connection",
  connection_update: "Update Connection",
  connection_added: "Connection Added",
  connection_added_successfully: "Connection has been added successfully!",
  connection_test: "Test connection",
  connection_tested_successfully: "Connection has been test successfully!",
  connection_tested_failed:
    "Connection test failed due to invalid credentials!",
  connection_save: "Save connection",
  connection_saved_successfully: "Connection has been save successfully!",
  connection_saved_failed: "Connection save failed due to invalid credentials!",
  connection_deleted: "Connection Deleted",
  connection_deleted_successfully: "Connection has been deleted successfully!",

  // Subscriptions
  subscription_upgrade_requested: "Subscription Upgrade Requested",
  subscription_upgrade_requested_successfully:
    "Subscription Upgrade Requested Successfully!",

  // Addon
  addon_subscribed: "Addon subscribed",
  addon_subscribed_successfully: "Addon subscribed successfully!",
  addon_unsubscribed: "Addon Unsubscribed",
  addon_unsubscribed_successfully: "Addon unsubscribed successfully!",

  // invoices
  model: "Model",
  imei: "IMEI",
  serial_no: "Serial No.",
  amount: "Amount",
  due_amount: "Due Amount",
  installments: "Installments",
  warranty_from_to: "Warranty From - To",
  warranty_starting_at: "Warranty Start",
  warranty_ending_at: "Warranty End",
  user_licenses_added_successfully: "User Licenses Added Successfully!",
  user_licenses_have_been_added_successfully:
    "User Licenses have been added successfully.",

  // topup
  wallet_details: "Wallet Details",
  available_credit: "Available credit",
  topup: "Top-up",
  balance_validity: "Balance Validity",
  topup_request_received: "Top-up request received",
  topup_request_received_successfully: "Top-up request received successfully!",
  confirmation: "Confirmation",
  check_confirmation_checkbox: "Kindly Check the confirmation checkbox!",
  enter_topup_amount: "Enter top-up amount",
  enter_reference_no: "Enter reference no",
  minimum_topup_amount_required: "Minimum top-up amount required",
  your_balance_is_low_please_recharge_minimum:
    "Your balance is low please recharge minimum",

  // Waiter component
  waiter: "Waiter",
  add_waiter: "Add Waiter",
  is_waiter: "Is Waiter",
  update_waiter: "Update Waiter",
  waiter_updated: "Waiter Updated",
  assign_waiter_to_order: "Assign Waiter to Order",
  select_waiter: "Select Waiter",
  validate_waiter_required: "The waiter field is required.",

  // job managment component
  job_person: "Job Person",
  is_job_person: "Is Job Person",
  select_job_person: "Select Job Person",
  job_person_updated: "Job Person Updated",
  update_job_person: "Update Job Person",
  validate_job_person_required: "The Job person is required.",

  // Inventory component
  adjustment_add: "Add Adjustment",
  stock_adjustment_done: "Stock adjustment Done",
  stock_adjustment_done_successfully:
    "Stock adjustment has been done successfully!",
  adjustment_history: "Adjustment History",
  direct_transfer: "Direct Transfer",
  request_transfer: "Request Transfer",
  cancel_transfer: "Cancel Transfer",
  accept_transfer: "Accept Transfer",
  reject_transfer: "Reject Transfer",
  confirm_transfer: "Confirm Transfer",
  transfer_edit: "Edit Transfer",
  transfer_added: "Transfer Added",
  transfer_added_successfully: "Transfer has been added successfully!",
  transfer_updated: "Transfer Updated",
  transfer_updated_successfully: "Transfer has been updated successfully!",

  // Sales Invoice
  invoice_add: "Add Invoice",
  invoice_added: "Invoice Added",
  invoice_added_successfully: "Invoice has been added successfully!",
  save_as_draft: "Save as Draft",
  issue_invoice: "Issue Invoice",
  register_payment: "Register Payment",

  // Table Columns
  col_product: "Product",
  col_price: "Price",
  col_category: "Category",
  col_unit: "Unit",
  col_barcode: "Barcode",
  col_action: "Action",
  col_order_id: "Order ID",
  col_amount: "Amount",
  col_tax: "VAT on Sale",
  col_type: "Type",
  col_quantity: "Quantity",
  col_status: "Status",
  col_created_at: "Created At",
  col_created_by: "Created by",
  col_paid_at: "Paid At",
  col_subtotal: "SubTotal",
  col_sold_quantities: "Sold Quantities",
  col_gross_sales: "Gross Sales",
  col_refund_quantities: "Refund Quantities",
  col_gross_refund: "Gross Refunds",
  col_start_date: "Start Date",
  col_end_date: "End Date",
  col_date: "Date",
  col_subscription_plan: "Subscription Plan",
  col_transaction_id: "Transaction ID",
  col_discount_percentage: "Discount Percentage",
  col_branches: "Branches",
  invoice_ID: "Invoice ID",
  subscription: "Subscription",
  license: "License",
  total_amount_charged: "Total Amount Charged",
  col_items: "Items",
  col_user: "User",
  col_payment: "Payment",
  col_new: "New Tickets",
  col_inprogress: "Tickets In Progress",
  col_done: "Tickets Done",
  col_closed: "Tickets Closed",
  col_description: "Description",
  col_attachment: "Attachment",
  col_allow_refund: "Allow Refund",
  col_allow_add_products: "Allow Add Products",
  col_allow_edit_products: "Allow Edit Products",
  col_odoo_status: "Odoo status",
  col_source: "Source",
  col_pending_orders: "Pending Orders",
  col_inprogress_orders: "Inprogress Orders",
  col_completed_orders: "Completed Orders",
  col_cancelled_orders: "Cancelled Orders",
  col_branch_from: "Branch From",
  col_branch_to: "Branch To",
  draft_invoices: "Draft Invoices",
  open_invoices: "Open Invoices",
  partially_paid_invoices: "Partially Paid Invoices",
  paid_invoices: "Paid Invoices",
  col_balance: "Balance",
  col_due_date: "Due Date",

  // Reports
  gross_sales: "Gross Sales",
  net_sales: "Net Sales",
  total_sales: "Total Sales",
  refunds: "Refunds",
  taxes: "VAT on Sales",
  sales: "Sales",
  payments: "Payments",
  net_total: "Net Total",
  total_refunds: "Total Refunds",
  total: "Total",
  vat: "VAT",
  total_vat: "Total VAT on Sale",
  quarterly_vat: "VAT on Quarterly Sales",
  payment_types: "Payment Types",
  quarterly_sales: "Quarterly Sales",
  sales_overview: "Sales Overview",

  // Warnings
  warning_remove: "Are you sure to remove this entity?",
  warning_delete: "Are you sure to delete this entity?",
  warning_logout: "Are you sure to logout?",
  warning_empty_basket:
    " Are you sure you want to delete these products from Basket?",
  warning_remove_product:
    " Are you sure you want to delete this product from Basket? ",
  warning_delete_stock_product:
    "Please make sure if you delete this product the stock will be deleted automatically.",
  warning_unsubscribe_addon:
    "If you unsubscribe the addon the data will be remove from server after 3 days",

  // Status
  status_paid: "PAID",
  status_refunded: "REFUNDED",

  // Miscellaneous
  yes: "Yes",
  no: "No",
  logout: "Logout",
  cancel: "Cancel",
  add: "Add",
  edit: "Edit",
  update: "Update",
  submit: "Submit",
  confirm: "Confirm",
  search: "Search",
  upload: "Upload",
  back: "Back",
  order_id: "Order ID",
  order_details: "Order Details",
  issued_at: "Issued At",
  date_range: "Date Range",
  delete_entity: "Delete Entity",
  something_went_wrong: "Something went wrong",
  refund: "Refund",
  user_can_edit_price_on_pos: "User Can Edit Price on POS",
  user_can_apply_discount: "User Can Apply Discount",
  user_see_transactions: "User Can See the Transactions",
  user_can_refund_transaction: "User Can Refund Transaction",
  user_can_add_edit_products: "User Can Add and Edit the Products",
  user_can_request_stock_transfer: "User Can Request Stock Transfer",
  user_can_approve_stock_transfer: "User Can Approve Stock Transfer",
  user_allowed_stock_adjustment: "User is Allowed to Stock Adjustment",
  user_can_add_edit_customers: "User Can Add and Edit the Customers",
  user_can_view_customers: "User Can View the Customers",
  user_can_add_pay_sales_invoices: "User Can Add and Pay the A4 Sales Invoice",
  user_can_view_sales_invoices: "User Can View the A4 Sales Invoice",
  is_machine_user: "Is Machine User",
  proceed: "Proceed",
  checkout: "Checkout",
  payment_type: "Payment Type",
  cash_collected: "Cash Collected",
  reference_no: "Reference No.",
  collected_by_cash: "Collected by Cash",
  collected_by_card: "Collected by Card",
  charged_amount: "Charged Amount",
  including_vat: "Including VAT",
  ask_more_cash: "Ask for more cash before proceeding!",
  return_back_amount: "Return Back",
  collect_more_cash: "Collect More Cash",
  print: "Print",
  declaration: "Declaration",
  declaration_text:
    "I hereby declare that the information provided in this form is accurate and complete. I confirm that any information is found incorrect and/or incomplete that leads a violation of regulations may initiate legal actions, and I accept that I am the responsible party for any and all charges, penalties and violations.",
  upgrade: "Upgrade",
  upgrade_subscription: "Upgrade Subscription",
  payment_successful: "Payment Successful!",
  your_payment_successfully: "Your payment has been successful.",
  payment_failed: "Payment Failed!",
  try_again_payment_failed: "Your payment has been failed. Please try again.",
  all: "All",
  cart_empty: "Your Cart is empty.",
  total_amount: "Total Amount",
  licenses: "License(s)",
  purchase: "Purchase",
  total_taxes: "Total VAT on Sales",
  credit_card: "Credit Card",
  stc_pay: "STC Pay",
  mada: "MADA",
  cash: "Cash",
  card: "Card",
  bank: "Bank",
  multi_payment: "Multi Payment",
  plz_fill_details: "Please fill the details below, to move further",
  billing_details: "Billing Details",
  active_subscription: "Active Subscription",
  available_upgrades: "Available Upgrades",
  already_requested: "Already Requested",
  request_upgrade: "Request Upgrade",
  request_subscription_upgrade: "Request Subscription Upgrade",
  pro_plan: "Pro Plan",
  basic_plan: "Basic Plan",
  discount: "Discount",
  select_a_discount: "Select a Discount",
  total_amount_payable: "Total Amount Payable",
  unpaid_invoice: "Unpaid Invoice!",
  pay_your_invoice: "Kindly pay your unpaid Invoice",
  show_invoices: "Show Invoices",
  what_type_of_refund: "What type of refund you want to perform?",
  full: "Full",
  partial: "Partial",
  refund_amount: "Refund Amount",
  qty: "Qty",
  year: "Year",
  month: "Month",
  day: "Day",
  additional: "Additional",
  additional_info: "Additional Info",
  users: "Users",
  pay_on: "Pay on",
  action: "Action",
  pay_now: "Pay Now",
  view_invoice: "View Invoice",
  one_time_payment: "One Time Payment",
  months_plan: " Months Plan",
  complete: "Complete",
  incomplete: "Incomplete",
  max_upload_size_10mb_warning: "Max upload size: 10MB",
  max_upload_size_2mb_warning: "Max upload size: 2MB",
  account_under_verification: "Your account is under verification!",
  notified_once_verified:
    "Thank you for signup, you will be notified on your mobile number once your account is verified.",
  account_blocked: "Your account is blocked!",
  kindly_contact_at: "Kindly contact us at contact@anypos.app",
  billing_address_same_as_shop_address: "Billing address same as shop address",
  is_vat_exempt: "I don't have VAT certificate",
  vat_exempt_latter_sample: "VAT exempt latter sample",
  vat_exempt_latter: "VAT Exempt Latter",
  connection: "Connection",
  connected: "Connected",
  disconnected: "Disconnected",
  send_to_odoo: "Send to Odoo",
  item_send_to_odoo_successfully: "Item has been Send to Odoo successfully!",
  download_report: "Download report",
  download_sales_report: "Download sales report",
  download_payments_report: "Download payments report",
  download_template: "Download Template",
  skip: "Skip",
  fill_the_details: "Fill the Details",
  kindly_complete_the_store_details_within:
    "Kindly complete the store details within",
  days_otherwise_your_account_will_be_blocked:
    "days otherwise your account will be blocked.",
  account_freezed: "Your account is freezed!",
  upgrade_subscription_unfreeze:
    "Kindly select a subscription upgrade to unfreeze it.",
  pay_generated_invoice_first: "Pay Generated Invoice First",
  you_selected_following_package: "You selected following package",
  unlimited: "Unlimited",
  per_user: "Per User",
  payment_modal: "Payment Modal",
  select_payment_method: "Select Payment Method",
  service_name: "Service Name",
  subscriptions_history: "Subscriptions History",
  spending_history: "Spending History",
  trial_days: "Trial Days",
  paid: "Paid",
  unpaid: "Unpaid",
  qr: "QR",
  dining_table: "Dining Table",
  note: "Note",
  clear: "Clear",
  selected_item: "Selected Item",
  pending: "Pending",
  completed: "Completed",
  cancelled: "Cancelled",
  rejected: "Rejected",
  available_stock: "Available Stock",
  requested_stock: "Requested Stock",
  current_stock: "Current Stock",
  your_branch: "Your Branch",
  approved_quantity: "Approved Quantity",
  requested_quantity: "Requested Quantity",
  insufficient_quantity: "Insufficient Quantity",
  out_of_stock: "Out of Stock",
  product_already_selected: "Product already Selected",
  basket: "Basket",
  search_product_name_here: "Search product name here",
  welcome_to: "Welcome to",
  call_to_waiter: "Call to Waiter",
  order: "Order",
  select_language: "Select Language",
  place_order: "Place Order",
  basket_empty: "Your basket is empty!",
  menu: "Menu",
  order_placed_successfully: "Your Order placed successfully",
  hi: "Hi",
  welcome_anypos: "Welcome to AnyPOS!",
  place_new_order: "Place New Order",
  your_order: "Your Order",
  expired: "Expired",
  is_expired: "Is Expired",
  late_payment: "Late Payment",
  is_late_payment: "Is Late Payment",
  addon_selected: "Addon Selected",
  all_prices_daily_basis: "All the prices are on a daily basis.",
  we_only_charge_use_addon: "We will only charge if you use the addon.",
  free: "Free",
  free_trial: "Free in Trial",
  need_addons: "Need Addons?",
};

export default trans_en_GB;
