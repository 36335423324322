import "../AuthStyle.css";
import {
  Form,
  Input,
  message,
  Button,
  Dropdown,
  Row,
  Col,
  Alert,
  Spin,
} from "antd";
import { useContext, useState } from "react";
import { AppContext } from "../../../context/AppContextProvider";
import { userLogin } from "../../../network/network";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  USER_TYPE_BUSINESS_OWNER,
  USER_TYPE_EMPLOYEE,
} from "../../../configs/constants";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { DownOutlined } from "@ant-design/icons";

const Login = () => {
  const { appConfig, setAppConfigWrapper } = useContext(AppContext);
  const { setAuthUser } = useContext(AppContext);
  const [errorMessages, setErrorMessages] = useState("");
  const [loading, setloading] = useState(false);
  let navigate = useNavigate();
  const { t } = useTranslation();

  const onFinish = (values) => {
    setloading(true);
    userLogin(values).then((response) => {
      setloading(false);
      if (
        response.data &&
        response.data.success &&
        (response.data.data.user.type === USER_TYPE_BUSINESS_OWNER ||
          response.data.data.user.type === USER_TYPE_EMPLOYEE)
      ) {
        localStorage.setItem(
          "access_token",
          response.data.data.user.access_token
        );
        setAuthUser(response.data.data.user);
        navigate("/home");
      } else {
        let error = response.response;
        if (error?.status === 422) {
          // message.error(error.data.message);
          let errorMessages = Object.values(error.data.message);
          setErrorMessages(errorMessages);
          console.log(errorMessages);
        } else if (error?.status === 500) {
          let errorMessages = Object.values(error.data.data.errors);
          errorMessages.forEach(function (key, value) {
            let temp = Object.values(key);
            message.error(temp[0]);
          });
        } else {
          // message.error(t("something_went_wrong"));
          setErrorMessages("something_went_wrong");
        }
      }
    });
  };

  const LangOptions = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            setAppConfigWrapper({ direction: "ltr" });
          }}
        >
          English
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            setAppConfigWrapper({ direction: "rtl" });
          }}
        >
          عربي
        </div>
      ),
    },
  ];

  const onCloseAlert = () => {
    setErrorMessages("");
  };

  return (
    <div
      style={{
        backgroundImage: "url('/images/bg-img.jpg')",
        backgroundSize: "cover",
        backgroundPosition: "top center",
        boxShadow: "inset 0 0 0 2000px rgb(51 51 51 / 10%)",
        minHeight: "100vh",
        direction: appConfig.direction
          ? appConfig.direction === "ltr"
            ? "ltr"
            : "rtl"
          : "ltr",
      }}
    >
      <div className="main-wrapper">
        <header className="header">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex" }}>
              <a
                href="https://anypos.app/"
                target="_blank"
                rel="noreferrer"
                style={{ display: "flex" }}
              >
                <img
                  src="/anypos-logo.svg"
                  alt="anypos"
                  className="main_logo"
                />
              </a>
            </div>
            <div
              className="Button-area"
              style={{
                display: "flex",
                columnGap: "10px",
                alignItems: "center",
              }}
            >
              <a href="https://anypos.app/" target="_blank" rel="noreferrer">
                <Button className="border_btn md_down_display_none">
                  {t("visit_website")}
                </Button>
              </a>

              <Link to="/register">
                <Button className="solid_btn1">{t("sign_up")}</Button>
              </Link>

              <Dropdown
                trigger={["click"]}
                menu={{
                  items: LangOptions,
                }}
                className="md_down_display_none"
              >
                <Button className="solid_btn2">
                  {appConfig.direction
                    ? appConfig.direction === "ltr"
                      ? "English"
                      : "عربي"
                    : "English"}
                  <span
                    style={{
                      margin: appConfig.direction
                        ? appConfig.direction === "ltr"
                          ? "0 0 0 8px"
                          : "0 8px 0 0"
                        : "0 0 0 8px",
                    }}
                  >
                    <DownOutlined
                      style={{
                        fontSize: "12px",
                      }}
                    />
                  </span>
                </Button>
              </Dropdown>
              <Dropdown
                trigger={["click"]}
                menu={{
                  items: LangOptions,
                }}
                className="md_up_display_none"
              >
                <img
                  src="/icons/Globe.png"
                  alt="language"
                  style={{ width: "fit-content", height: "fit-content" }}
                />
              </Dropdown>
            </div>
          </div>
        </header>
        <div className="auth_area">
          <Row>
            <Col xs={24} sm={3} md={4} lg={6} xl={7} xxl={8}></Col>
            <Col xs={24} sm={18} md={16} lg={12} xl={10} xxl={8}>
              {errorMessages && (
                <Alert
                  message={errorMessages}
                  showIcon
                  type="error"
                  closable
                  onClose={onCloseAlert}
                  style={{ marginBottom: "20px" }}
                />
              )}
              <Spin spinning={loading}>
                <div
                  style={{
                    backgroundColor: "#ffffffcc",
                    padding: "20px",
                    boxShadow: "0px 0px 10px #00000029",
                    borderRadius: "20px",
                  }}
                >
                  <Form
                    name="login-user-form"
                    layout="vertical"
                    onFinish={onFinish}
                  >
                    <div
                      style={{
                        padding: "10px 0 30px 0",
                        textAlign: "center",
                      }}
                    >
                      <h2>{t("login")}</h2>
                    </div>

                    <Form.Item
                      name="phone"
                      label={t("phone_number") + " eg: +966 5xxxxxxx"}
                      rules={[
                        {
                          required: true,
                          message: t("validate_phone_required"),
                        },
                        {
                          min: 12,
                          message: t("validate_phone_exact_limit"),
                        },
                        {
                          max: 12,
                          message: t("validate_phone_exact_limit"),
                        },
                      ]}
                    >
                      <PhoneInput
                        localization={["sa"]}
                        country={"sa"}
                        onlyCountries={["sa"]}
                        masks={{ sa: "(...) ..-..-.." }}
                        placeholder="+966 (123) 45-67-89"
                        style={{
                          direction: "ltr",
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={t("password")}
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: t("validate_password_required"),
                        },
                      ]}
                    >
                      <Input.Password size="large" />
                    </Form.Item>

                    <div
                      style={{
                        width: "100%",
                        justifyContent: "space-between",
                        display: "flex",
                        margin: "20px 0 10px 0",
                      }}
                    >
                      <Link to="/forgot">
                        <Button className="border_btn">
                          {t("forgot_password")}
                        </Button>
                      </Link>
                      <Button htmlType="submit" className="solid_btn1">
                        {t("login")}
                      </Button>
                    </div>
                  </Form>
                </div>
              </Spin>
            </Col>
            <Col xs={24} sm={3} md={4} lg={6} xl={7} xxl={8}></Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Login;
