import "../AuthStyle.css";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { AppContext } from "../../../context/AppContextProvider";
import {
  forgotPassword,
  resetPassword,
  verifyForgotOtp,
} from "../../../network/network";
import {
  Form,
  Input,
  Button,
  message,
  Col,
  Row,
  Dropdown,
  Alert,
  Spin,
} from "antd";

import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { DownOutlined } from "@ant-design/icons";

const Forgot = () => {
  const { appConfig, setAppConfigWrapper } = useContext(AppContext);
  const [OTPFormVisible, setOTPFormVisible] = useState(false);
  const [resetFormVisible, setResetFormVisible] = useState(false);
  const [token, setToken] = useState(false);
  const [loading, setloading] = useState(false);
  const [errorMessages, setErrorMessages] = useState("");

  const [forgotForm] = Form.useForm();
  const { t } = useTranslation();
  let navigate = useNavigate();

  const handleForgotValidation = () => {
    setloading(true);
    forgotForm.validateFields().then((values) => {
      forgotPassword(values) // OTP SMS sent as well
        .then((response) => {
          setloading(false);
          if (response.data && response.data.success) {
            setOTPFormVisible(true);
          } else {
            let error = response.response;
            if (error.status === 400) {
              let errorMessages = Object.values(error.data.message);
              setErrorMessages(errorMessages);
            } else {
              let errorMessages = Object.values(error.data.message);
              errorMessages.forEach(function (key, value) {
                let temp = Object.values(key);
                message.error(temp[0]);
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const onOTPFormFinish = (otpValues) => {
    setloading(true);
    forgotForm.validateFields().then((values) => {
      verifyForgotOtp({
        ...otpValues,
        ...values,
      })
        .then((response) => {
          setloading(false);
          if (response.data && response.data.success) {
            setToken(response.data.token);
            setResetFormVisible(true);
          } else {
            let error = response.response;
            if (error.status === 400) {
              let errorMessages = Object.values(error.data.message);
              setErrorMessages(errorMessages);
            }
            if (error.status === 500) {
              let errorMessages = Object.values(error.data.message);
              errorMessages.forEach(function (key, value) {
                let temp = Object.values(key);
                message.error(temp[0]);
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const onResetPassword = (resetPassValues) => {
    setloading(true);
    forgotForm.validateFields().then((values) => {
      resetPassword({
        ...resetPassValues,
        ...values,
        token,
      })
        .then((response) => {
          setloading(false);
          if (response.data && response.data.success) {
            message.success(t("reset_password_successfully"));
            navigate("/login");
          } else {
            let error = response.response;
            if (error.status === 400) {
              let errorMessages = Object.values(error.data.message);
              setErrorMessages(errorMessages);
            } else {
              let errorMessages = Object.values(error.data.message);
              errorMessages.forEach(function (key, value) {
                let temp = Object.values(key);
                message.error(temp[0]);
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const LangOptions = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            setAppConfigWrapper({ direction: "ltr" });
          }}
        >
          English
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            setAppConfigWrapper({ direction: "rtl" });
          }}
        >
          عربي
        </div>
      ),
    },
  ];

  const onCloseAlert = () => {
    setErrorMessages("");
  };

  return (
    <>
      <div
        style={{
          backgroundImage: "url('/images/bg-img.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          boxShadow: "inset 0 0 0 2000px rgb(51 51 51 / 10%)",
          minHeight: "100vh",
          direction: appConfig.direction
            ? appConfig.direction === "ltr"
              ? "ltr"
              : "rtl"
            : "ltr",
        }}
      >
        <div className="main-wrapper">
          <header className="header">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex" }}>
                <a
                  href="https://anypos.app/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ display: "flex" }}
                >
                  <img
                    src="/anypos-logo.svg"
                    alt="anypos"
                    className="main_logo"
                  />
                </a>
              </div>
              <div
                className="Button-area"
                style={{
                  display: "flex",
                  columnGap: "10px",
                  alignItems: "center",
                }}
              >
                <a href="https://anypos.app/" target="_blank" rel="noreferrer">
                  <Button className="border_btn md_down_display_none">
                    {t("visit_website")}
                  </Button>
                </a>

                <Link to="/login">
                  <Button className="solid_btn1">{t("login")}</Button>
                </Link>

                <Dropdown
                  trigger={["click"]}
                  menu={{
                    items: LangOptions,
                  }}
                  className="md_down_display_none"
                >
                  <Button className="solid_btn2">
                    {appConfig.direction
                      ? appConfig.direction === "ltr"
                        ? "English"
                        : "عربي"
                      : "English"}
                    <span
                      style={{
                        margin: appConfig.direction
                          ? appConfig.direction === "ltr"
                            ? "0 0 0 8px"
                            : "0 8px 0 0"
                          : "0 0 0 8px",
                      }}
                    >
                      <DownOutlined
                        style={{
                          fontSize: "12px",
                        }}
                      />
                    </span>
                  </Button>
                </Dropdown>
                <Dropdown
                  trigger={["click"]}
                  menu={{
                    items: LangOptions,
                  }}
                  className="md_up_display_none"
                >
                  <img
                    src="/icons/Globe.png"
                    alt="language"
                    style={{ width: "fit-content", height: "fit-content" }}
                  />
                </Dropdown>
              </div>
            </div>
          </header>
          <div className="auth_area">
            <Row>
              <Col xs={24} sm={3} md={4} lg={6} xl={7} xxl={8}></Col>
              <Col xs={24} sm={18} md={16} lg={12} xl={10} xxl={8}>
                {errorMessages && (
                  <Alert
                    message={errorMessages}
                    showIcon
                    type="error"
                    closable
                    onClose={onCloseAlert}
                    style={{ marginBottom: "20px" }}
                  />
                )}
                <Spin spinning={loading}>
                  <div
                    style={{
                      backgroundColor: "#ffffffcc",
                      padding: "20px",
                      boxShadow: "0px 0px 10px #00000029",
                      borderRadius: "20px",
                    }}
                  >
                    <Form
                      name="forgot-user-form"
                      layout="vertical"
                      onFinish={handleForgotValidation}
                      form={forgotForm}
                      style={{
                        display:
                          !OTPFormVisible && !resetFormVisible
                            ? "block"
                            : "none",
                      }}
                    >
                      <div
                        style={{
                          padding: "10px 0 30px 0",
                          textAlign: "center",
                        }}
                      >
                        <h2>{t("forgot")}</h2>
                      </div>
                      <Form.Item
                        name="phone"
                        label={t("phone_number") + " eg: +966 5xxxxxxx"}
                        rules={[
                          {
                            required: true,
                            message: t("validate_phone_required"),
                          },
                          {
                            min: 12,
                            message: t("validate_phone_exact_limit"),
                          },
                          {
                            max: 12,
                            message: t("validate_phone_exact_limit"),
                          },
                        ]}
                      >
                        <PhoneInput
                          localization={["sa"]}
                          country={"sa"}
                          onlyCountries={["sa"]}
                          masks={{ sa: "(...) ..-..-.." }}
                          placeholder="+966 (123) 45-67-89"
                          style={{
                            direction: "ltr",
                          }}
                        />
                      </Form.Item>
                      <div
                        style={{
                          width: "100%",
                          justifyContent: "center",
                          display: "flex",
                          margin: "20px 0 10px 0",
                        }}
                      >
                        <Button htmlType="submit" className="solid_btn1">
                          {t("send_otp")}
                        </Button>
                      </div>
                    </Form>

                    <Form
                      name="validate-otp-form"
                      layout="vertical"
                      onFinish={onOTPFormFinish}
                      style={{
                        display:
                          OTPFormVisible && !resetFormVisible
                            ? "block"
                            : "none",
                      }}
                    >
                      <div
                        style={{
                          padding: "10px 0 30px 0",
                          textAlign: "center",
                        }}
                      >
                        <h2>{t("verify_otp")}</h2>
                      </div>
                      <p>
                        {t("opt_message")}
                        <br />
                        {t("if_you_want_to_change_phone_number")}{" "}
                        <Link to="#" onClick={() => setOTPFormVisible(false)}>
                          {t("click_here")}
                        </Link>
                      </p>
                      <Form.Item
                        name="otp"
                        label="OTP"
                        rules={[
                          {
                            required: true,
                          },
                          {
                            max: 6,
                          },
                          {
                            min: 6,
                          },
                        ]}
                        hasFeedback
                      >
                        <Input.Password
                          type="number"
                          minLength={6}
                          maxLength={6}
                          size="large"
                        />
                      </Form.Item>
                      <div
                        style={{
                          width: "100%",
                          margin: "20px 0 10px 0",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          className="border_btn"
                          onClick={() => setOTPFormVisible(false)}
                        >
                          {t("back")}
                        </Button>
                        <Button htmlType="submit" className="solid_btn1">
                          {t("validate")}
                        </Button>
                      </div>
                    </Form>

                    <Form
                      name="reset-pass-form"
                      layout="vertical"
                      onFinish={onResetPassword}
                      style={{
                        display: resetFormVisible ? "block" : "none",
                      }}
                    >
                      <div
                        style={{
                          padding: "10px 0 30px 0",
                          textAlign: "center",
                        }}
                      >
                        <h2>{t("reset_password")}</h2>
                      </div>
                      <Form.Item
                        name="password"
                        label={t("password")}
                        rules={[
                          {
                            required: true,
                            message: t("validate_password_required"),
                          },
                          {
                            min: 6,
                            message: t("validate_password_min_characters"),
                          },
                        ]}
                        hasFeedback
                      >
                        <Input.Password size="large" />
                      </Form.Item>
                      <Form.Item
                        name="password_confirmation"
                        label={t("confirm_password")}
                        dependencies={["password"]}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: t("validate_password_confirm_not_matched"),
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("password") === value
                              ) {
                                return Promise.resolve();
                              }

                              return Promise.reject(
                                new Error(
                                  t("validate_password_confirm_not_matched")
                                )
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password size="large" />
                      </Form.Item>
                      <div
                        style={{
                          width: "100%",
                          margin: "20px 0 10px 0",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button htmlType="submit" className="solid_btn1">
                          {t("reset")}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </Spin>
              </Col>
              <Col xs={24} sm={3} md={4} lg={6} xl={7} xxl={8}></Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgot;
